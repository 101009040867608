<template>
  <div>
    <template v-for="(item, index) in list">
      <div class="item" :key="index">
        <div class="item-name">
          <span>套餐{{index+1}}</span>
          <template v-if="!disabled">
            <i class="el-icon-circle-plus-outline" @click="addModule"></i>
            <i class="el-icon-remove-outline" @click="delModule(index)" v-if="index>=1"></i>
          </template>
        </div>
        <el-form>
          <el-form-item label="选择活动本品" label-width="100px">
            <div v-if="!disabled">
              <el-button type="primary" @click="openDialog(index, 'productList')">点击选择</el-button>
              <el-button @click="clearTable(index, 'productList')">清空</el-button>
            </div>
            <vxe-table class="item-table" :data="item.productList">
              <vxe-table-column title="商品编码" field="productCode" min-width="100"></vxe-table-column>
              <vxe-table-column title="商品名称" field="productName" min-width="100"></vxe-table-column>
              <vxe-table-column title="操作" min-width="100">
                <template v-slot="{rowIndex}" v-if="!disabled">
                  <el-button type="text" @click="delTable(index, rowIndex, 'productList')">删除</el-button>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
          <el-form-item label="选择活动赠品" label-width="100px">
            <div v-if="!disabled">
              <el-button type="primary" @click="openDialog(index, 'giftList')">点击选择</el-button>
              <el-button @click="clearTable(index, 'giftList')">清空</el-button>
            </div>
            <vxe-table class="item-table" :data="item.giftList" border>
              <vxe-table-column title="商品编码" field="productCode" min-width="100"></vxe-table-column>
              <vxe-table-column title="商品名称" field="productName" min-width="100"></vxe-table-column>
              <vxe-table-column title="操作" min-width="100">
                <template v-slot="{rowIndex}" v-if="!disabled">
                  <el-button type="text" @click="delTable(index, rowIndex, 'giftList')">删除</el-button>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
          <el-form-item label="套餐备注" label-width="100px" required>
            <el-input type="textarea" v-model="item.remarks" placeholder="请添加套餐备注信息" clearable :disabled="disabled"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <ProductDialog :visible="visibleDialog" @closeDialog="closeDialog" @dialogSubmit="dialogSubmit"></ProductDialog>
  </div>
</template>
<script>
import ProductDialog from './product_dialog.vue';

export default {
  name: 'package',
  components: {
    ProductDialog,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          this.list = val;
        }
      },
    },
  },
  data() {
    return {
      visibleDialog: false,
      // 点击某一个模块的索引
      index: 0,
      key: 'productList',
      list: [{}],
    };
  },
  methods: {
    /** @desc 添加一个模块 * */
    addModule() {
      this.list.push({});
    },
    /** @desc 删除一个模块 * */
    delModule(index) {
      this.list.splice(index, 1);
      this.emitChange();
    },
    /** @desc 显示选择商品弹窗 * */
    openDialog(index, key) {
      this.index = index;
      this.key = key;
      this.visibleDialog = true;
    },
    /** @desc 关闭选择商品弹窗 * */
    closeDialog() {
      this.visibleDialog = false;
    },
    /** @desc 选择商品弹窗提交按钮 * */
    dialogSubmit(val) {
      const list = this.uniqueArr(this.list[this.index][this.key] || [], val, 'productCode');
      this.$set(this.list[this.index], this.key, list);
      this.closeDialog();
      this.emitChange();
    },
    /**
     * @desc table中删除行
     * @param{index} 列表索引
     * @param{rowIndex} 列表中子列表索引
     * @param{key} 哪个子列表
     * */
    delTable(index, rowIndex, key) {
      const list = this.list[index][key];
      list.splice(rowIndex, 1);
      this.$set(this.list[index], key, list || []);
      this.emitChange();
    },
    /**
     * @desc table中删除行
     * @param{index} 列表索引
     * @param{key} 哪个子列表
     * */
    clearTable(index, key) {
      this.$set(this.list[index], key, []);
      this.emitChange();
    },
    /**
     * @desc 两个数组去重
     * @param {Array} array1 数组1
     * @param {Array} array2 数组2
     * @param {String} key 去重字段
     * */
    uniqueArr(array1, array2, key) {
      const arraydata = array1.concat(array2);
      const hash = {};
      const newArr = arraydata.reduce((item, next) => {
        if (!hash[next[key]]) {
          hash[next[key]] = true;
          item.push(next);
        }
        return item;
      }, []);
      return newArr;
    },
    /** @desc 数据刷新 * */
    emitChange() {
      this.$emit('input', this.list || []);
    },
  },
};
</script>
<style lang="less" scoped>
.item{
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  .item-name{
    font-size: 14px;
    display: flex;
    align-items: center;
    span{
      padding-right: 5px;
    }
    .el-icon-circle-plus-outline{
      font-size: 20px;
      padding: 0 5px;
      cursor: pointer;
      color: #409eff;
    }
    .el-icon-remove-outline{
      font-size: 20px;
      padding: 0 5px;
      cursor: pointer;
      color: #F56C6C;
    }
  }
  .item-table{
    margin-top: 16px;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          _c(
            "div",
            { key: index, staticClass: "item" },
            [
              _c(
                "div",
                { staticClass: "item-name" },
                [
                  _c("span", [_vm._v("套餐" + _vm._s(index + 1))]),
                  !_vm.disabled
                    ? [
                        _c("i", {
                          staticClass: "el-icon-circle-plus-outline",
                          on: { click: _vm.addModule },
                        }),
                        index >= 1
                          ? _c("i", {
                              staticClass: "el-icon-remove-outline",
                              on: {
                                click: function ($event) {
                                  return _vm.delModule(index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "选择活动本品", "label-width": "100px" },
                    },
                    [
                      !_vm.disabled
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialog(
                                        index,
                                        "productList"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("点击选择")]
                              ),
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearTable(
                                        index,
                                        "productList"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("清空")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "vxe-table",
                        {
                          staticClass: "item-table",
                          attrs: { data: item.productList },
                        },
                        [
                          _c("vxe-table-column", {
                            attrs: {
                              title: "商品编码",
                              field: "productCode",
                              "min-width": "100",
                            },
                          }),
                          _c("vxe-table-column", {
                            attrs: {
                              title: "商品名称",
                              field: "productName",
                              "min-width": "100",
                            },
                          }),
                          _c("vxe-table-column", {
                            attrs: { title: "操作", "min-width": "100" },
                            scopedSlots: _vm._u(
                              [
                                !_vm.disabled
                                  ? {
                                      key: "default",
                                      fn: function (ref) {
                                        var rowIndex = ref.rowIndex
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delTable(
                                                    index,
                                                    rowIndex,
                                                    "productList"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "选择活动赠品", "label-width": "100px" },
                    },
                    [
                      !_vm.disabled
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialog(index, "giftList")
                                    },
                                  },
                                },
                                [_vm._v("点击选择")]
                              ),
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearTable(index, "giftList")
                                    },
                                  },
                                },
                                [_vm._v("清空")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "vxe-table",
                        {
                          staticClass: "item-table",
                          attrs: { data: item.giftList, border: "" },
                        },
                        [
                          _c("vxe-table-column", {
                            attrs: {
                              title: "商品编码",
                              field: "productCode",
                              "min-width": "100",
                            },
                          }),
                          _c("vxe-table-column", {
                            attrs: {
                              title: "商品名称",
                              field: "productName",
                              "min-width": "100",
                            },
                          }),
                          _c("vxe-table-column", {
                            attrs: { title: "操作", "min-width": "100" },
                            scopedSlots: _vm._u(
                              [
                                !_vm.disabled
                                  ? {
                                      key: "default",
                                      fn: function (ref) {
                                        var rowIndex = ref.rowIndex
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delTable(
                                                    index,
                                                    rowIndex,
                                                    "giftList"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "套餐备注",
                        "label-width": "100px",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请添加套餐备注信息",
                          clearable: "",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: item.remarks,
                          callback: function ($$v) {
                            _vm.$set(item, "remarks", $$v)
                          },
                          expression: "item.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      }),
      _c("ProductDialog", {
        attrs: { visible: _vm.visibleDialog },
        on: { closeDialog: _vm.closeDialog, dialogSubmit: _vm.dialogSubmit },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
import TablePage from '../../../../../components/table_page';
import request from '../../../../../utils/request';
import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      /** @desc 表单配置参数 */
      formConfig: {},
      /** @desc 弹窗配置参数 */
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      options: [],
    };
  },
  async created() {
    await this.getConfigList('sfa_activity_programManage_list');
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'schemeName') {
        this.formConfig.type = 'view';
        this.formConfig.id = row.id;
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      const urls = '';
      const text = '';
      if (val.code === 'add') {
        this.formConfig.type = val.code;
        this.openFull();
      } else if (val.code === 'edit') {
        request.post('/sfa/sfaActSchemeController/isUpdate', { id: row.id }).then((res) => {
          this.formConfig.type = val.code;
          this.formConfig.id = row.id;
          this.modalConfig.title = '编辑';
          this.openFull();
        });
      }
    },
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.post(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
};
</script>

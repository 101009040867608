var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "组织", name: "first" } },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-plus",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addTable("orgList")
                        },
                      },
                    },
                    [_vm._v("添加组织")]
                  )
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.clearTable("orgList")
                        },
                      },
                    },
                    [_vm._v("清空")]
                  )
                : _vm._e(),
              _c(
                "vxe-table",
                {
                  staticClass: "table",
                  attrs: { data: _vm.orgList, border: "" },
                },
                [
                  _c("vxe-table-column", {
                    attrs: {
                      field: "code",
                      title: "组织编码",
                      "min-width": "100",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "name",
                      title: "组织名称",
                      "min-width": "100",
                    },
                  }),
                  !_vm.disabled
                    ? _c("vxe-table-column", {
                        attrs: { title: "操作", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var rowIndex = ref.rowIndex
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delTable(
                                            rowIndex,
                                            "orgList"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3932776400
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "职位级别", name: "second" } },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-plus",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addTable("positionLevelList")
                        },
                      },
                    },
                    [_vm._v("添加职位级别")]
                  )
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.clearTable("positionLevelList")
                        },
                      },
                    },
                    [_vm._v("清空")]
                  )
                : _vm._e(),
              _c(
                "vxe-table",
                {
                  staticClass: "table",
                  attrs: { data: _vm.positionLevelList, border: "" },
                },
                [
                  _c("vxe-table-column", {
                    attrs: {
                      field: "positionLevelCode",
                      title: "职位级别编码",
                      "min-width": "100",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "positionLevelName",
                      title: "职位级别名称",
                      "min-width": "100",
                    },
                  }),
                  !_vm.disabled
                    ? _c("vxe-table-column", {
                        attrs: { title: "操作", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var rowIndex = ref.rowIndex
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delTable(
                                            rowIndex,
                                            "positionLevelList"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3247684261
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("OrgDialog", {
        attrs: { visible: _vm.visibleOrgDialog, orgList: _vm.orgList },
        on: { closeDialog: _vm.closeDialog, dialogSubmit: _vm.dialogSubmit },
      }),
      _c("PositionDialog", {
        attrs: { visible: _vm.visiblePositionDialog },
        on: { closeDialog: _vm.closeDialog, dialogSubmit: _vm.dialogSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import Package from './components/package.vue';
import Range from './components/range.vue';

formCreate.component('Package', Package);
formCreate.component('Range', Range);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getBeforFormRule('sfa_activity_programManage_add');
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'datePicker') {
        v.props = {
          ...v.props,
          type: 'daterange',
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
        };
      } else if (v.field === 'actSchemePictureList') {
        v.props = {
          ...v.props,
          showFileList: true,
          uploadType: 'file',
          listType: 'text',
          fileMsg: '只能上传pdf文件',
          accept: '.pdf',
        };
      }
      if (this.formConfig.type === 'view') {
        v.props = {
          ...v.props,
          disabled: true,
        };
        this.buttons.submit = false;
      }
    });
    this.rule = rule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      request.get('/sfa/sfaActSchemeController/queryDetailById', { id: this.formConfig.id }).then((res) => {
        if (res.success) {
          const { result } = res;
          const packages = [];
          result.datePicker = [result.schemeStartTime, result.schemeEndTime];
          if (result.actSchemeSetmealList && result.actSchemeSetmealList.length > 0) {
            result.actSchemeSetmealList.map((v) => {
              const productList = [];
              const giftList = [];
              if (v.actSchemeProductList && v.actSchemeProductList.length > 0) {
                v.actSchemeProductList.map((m) => {
                  if (m.itmeType === '1') {
                    productList.push({
                      productCode: m.productCode,
                      productName: m.productName,
                      productLevelCode: m.seriesCode,
                      productLevelName: m.seriesName,
                    });
                  } else if (m.itmeType === '2') {
                    giftList.push({
                      productCode: m.productCode,
                      productName: m.productName,
                      productLevelCode: m.seriesCode,
                      productLevelName: m.seriesName,
                    });
                  }
                  return m;
                });
              }
              packages.push({
                productList,
                giftList,
                remarks: v.remarks,
              });
              return v;
            });
          }
          let ranges = {};
          if (result.actSchemeRangeList && result.actSchemeRangeList.length > 0) {
            const orgList = [];
            const positionLevelList = [];
            result.actSchemeRangeList.map((v) => {
              if (v.rangeType === '0') {
                orgList.push(v);
              } else if (v.rangeType === '1') {
                positionLevelList.push({
                  positionLevelCode: v.code,
                  positionLevelName: v.name,
                });
              }
              return v;
            });
            ranges = {
              orgList,
              positionLevelList,
            };
          }
          this.setValue(result);
          this.setValue({
            packages,
            ranges,
          });
          console.log(this.getFormData());
          this.row = res.result;
        }
      });
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      console.log('formData', formData);
      if (!formData) return false;
      let params = formData;
      params.schemeStartTime = formData.datePicker && formData.datePicker.length > 0 ? formData.datePicker[0] : null;
      params.schemeEndTime = formData.datePicker && formData.datePicker.length > 0 ? formData.datePicker[1] : null;
      params.actSchemeSetmealList = [];
      if (formData.packages && formData.packages.length > 0) {
        formData.packages.map((v) => {
          let productList = [];
          let giftList = [];
          if (v.productList && v.productList.length > 0) {
            productList = v.productList.map((m) => ({
              productCode: m.productCode, productName: m.productName, seriesCode: m.productLevelCode, seriesName: m.productLevelName, itmeType: '1',
            }));
          }
          if (v.giftList && v.giftList.length > 0) {
            giftList = v.giftList.map((m) => ({
              productCode: m.productCode, productName: m.productName, seriesCode: m.productLevelCode, seriesName: m.productLevelName, itmeType: '2',
            }));
          }
          params.actSchemeSetmealList.push({
            remarks: v.remarks || null,
            actSchemeProductList: productList.concat(giftList),
          });
          return v;
        });
      }
      for (let i = 0; i < params.actSchemeSetmealList.length; i += 1) {
        if (!params.actSchemeSetmealList[i].remarks) {
          this.$message.warning(`请输入套餐${i + 1}的套餐备注！`);
          return false;
        }
      }
      let orgList = formData.ranges ? formData.ranges.orgList : [];
      orgList = orgList.map((v) => ({ code: v.code, name: v.name, rangeType: '0' }));
      let positionLevelList = formData.ranges ? formData.ranges.positionLevelList : [];
      positionLevelList = positionLevelList.map((v) => ({ code: v.positionLevelCode, name: v.positionLevelName, rangeType: '1' }));
      params.actSchemeRangeList = orgList.concat(positionLevelList);
      delete params.datePicker;
      delete params.packages;
      delete params.ranges;
      let url = '/sfa/sfaActSchemeController/save';

      if (this.formConfig.type === 'edit') {
        url = '/sfa/sfaActSchemeController/update';
        params = Object.assign(this.row, params);
      }

      request.post(url, params).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>

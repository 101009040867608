var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.dialog_btnClose },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请输入职位级别名称",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": function (query) {
                          _vm.remoteMethod(query)
                        },
                        loading: _vm.loading,
                        filterable: "",
                        clearable: "",
                      },
                      on: {
                        clear: function ($event) {
                          return _vm.getClear()
                        },
                        change: function ($event) {
                          return _vm.getChange($event)
                        },
                      },
                      model: {
                        value: _vm.positionLevelCode,
                        callback: function ($$v) {
                          _vm.positionLevelCode = $$v
                        },
                        expression: "positionLevelCode",
                      },
                    },
                    _vm._l(_vm.positionLevelOptions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.positionLevelName,
                          value: item.positionLevelCode,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              staticClass: "vxeTable",
              attrs: {
                data: _vm.tableList,
                border: "",
                "row-id": "productCode",
              },
              on: {
                "checkbox-change": _vm.dialog_checkboxChange,
                "checkbox-all": _vm.dialog_checkboxChange,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "60" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "positionLevelCode",
                  title: "职位级别编码",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "positionLevelName",
                  title: "职位级别名称",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: { title: "操作", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var rowIndex = ref.rowIndex
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delTable(rowIndex, row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.dialog_btnConfirm },
                },
                [_vm._v("提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.dialog_btnClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="组织" name="first">
        <el-button type="primary" v-if="!disabled" size="small" icon="el-icon-plus" @click="addTable('orgList')">添加组织</el-button>
        <el-button type="danger" v-if="!disabled" size="small" icon="el-icon-delete" @click="clearTable('orgList')">清空</el-button>
        <vxe-table class="table" :data="orgList" border>
          <vxe-table-column field="code" title="组织编码" min-width="100"></vxe-table-column>
          <vxe-table-column field="name" title="组织名称" min-width="100"></vxe-table-column>
          <vxe-table-column title="操作" min-width="100" v-if="!disabled">
            <template v-slot="{ rowIndex }">
              <el-button type="text" @click="delTable(rowIndex, 'orgList')">删除</el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
      </el-tab-pane>
      <el-tab-pane label="职位级别" name="second">
        <el-button type="primary" v-if="!disabled" size="small" icon="el-icon-plus" @click="addTable('positionLevelList')">添加职位级别</el-button>
        <el-button type="danger" v-if="!disabled" size="small" icon="el-icon-delete" @click="clearTable('positionLevelList')">清空</el-button>
        <vxe-table class="table" :data="positionLevelList" border>
          <vxe-table-column field="positionLevelCode" title="职位级别编码" min-width="100"></vxe-table-column>
          <vxe-table-column field="positionLevelName" title="职位级别名称" min-width="100"></vxe-table-column>
          <vxe-table-column title="操作" min-width="100" v-if="!disabled">
            <template v-slot="{ rowIndex }">
              <el-button type="text" @click="delTable(rowIndex, 'positionLevelList')">删除</el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
      </el-tab-pane>
    </el-tabs>
    <OrgDialog :visible="visibleOrgDialog" :orgList="orgList" @closeDialog="closeDialog" @dialogSubmit="dialogSubmit"></OrgDialog>
    <PositionDialog :visible="visiblePositionDialog" @closeDialog="closeDialog" @dialogSubmit="dialogSubmit"></PositionDialog>
  </div>
</template>
<script>
import OrgDialog from './org_dialog.vue';
import PositionDialog from './position_level_dialog.vue';

export default {
  name: 'range',
  components: {
    OrgDialog,
    PositionDialog,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log('range:', val);
        if (val && val.orgList) {
          this.orgList = val.orgList;
        }
        if (val && val.positionLevelList) {
          this.positionLevelList = val.positionLevelList;
        }
      },
    },
  },
  data() {
    return {
      activeName: 'first',
      orgList: [],
      positionLevelList: [],
      type: '',
      visibleOrgDialog: false,
      visiblePositionDialog: false,
    };
  },
  methods: {
    /** @desc tabs切换 * */
    handleClick(val) {
      this.activeName = val.name;
    },
    /** @desc 点击添加按钮 * */
    addTable(key) {
      this.key = key;
      if (this.key === 'orgList') {
        this.visibleOrgDialog = true;
      } else if (this.key === 'positionLevelList') {
        this.visiblePositionDialog = true;
      }
    },
    /** @desc 关闭选择商品弹窗 * */
    closeDialog() {
      if (this.key === 'orgList') {
        this.visibleOrgDialog = false;
      } else if (this.key === 'positionLevelList') {
        this.visiblePositionDialog = false;
      }
    },
    /** @desc 选择商品弹窗提交按钮 * */
    dialogSubmit(val) {
      if (this.key === 'orgList') {
        this.$set(this, this.key, val || []);
      } else if (this.key === 'positionLevelList') {
        const list = this.uniqueArr(this[this.key] || [], val, 'productCode');
        this.$set(this, this.key, list);
      }
      this.closeDialog();
      this.emitChange();
    },
    /**
     * @desc 两个数组去重
     * @param {Array} array1 数组1
     * @param {Array} array2 数组2
     * @param {String} key 去重字段
     * */
    uniqueArr(array1, array2, key) {
      const arraydata = array1.concat(array2);
      const hash = {};
      const newArr = arraydata.reduce((item, next) => {
        if (!hash[next[key]]) {
          hash[next[key]] = true;
          item.push(next);
        }
        return item;
      }, []);
      return newArr;
    },
    /** @desc 点击清空按钮 * */
    clearTable(key) {
      this.$set(this, key, []);
      this.emitChange();
    },
    /** @desc 点击表格中的删除 * */
    delTable(rowIndex, key) {
      const list = this[key];
      list.splice(rowIndex, 1);
      this.$set(this, key, list);
      this.emitChange();
    },
    /** @desc 数据刷新 * */
    emitChange() {
      const value = {
        orgList: this.orgList || [],
        positionLevelList: this.positionLevelList || [],
      };
      this.$emit('input', value);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__item{
  height: 32px;
  line-height: 32px;
}
.table{
  margin-top: 15px;
}
</style>

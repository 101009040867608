<template>
  <div>
    <Modal :modalConfig="modalConfig" @onClose="dialog_btnClose">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-select
            placeholder="请输入商品编码"
            v-model="productCode"
            remote
            reserve-keyword
            :remote-method="(query)=>{remoteMethod(query,'productCode')}"
            :loading="loading"
            filterable
            clearable
            @clear="getClear('productCode')"
            @change="getChange($event, 'productCode')" >
            <el-option v-for="(item, index) in productCodeOptions" :key="index" :label="item.productCode" :value="item.productCode"></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            placeholder="请输入商品名称"
            v-model="productName"
            remote
            reserve-keyword
            :remote-method="(query)=>{remoteMethod(query,'productName')}"
            :loading="loading"
            filterable
            clearable
            @clear="getClear('productName')"
            @change="getChange($event, 'productName')" >
            <el-option v-for="(item, index) in productNameOptions" :key="index" :label="item.productName" :value="item.productCode"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <vxe-table :data="tableList" border row-id="productCode" class="vxeTable" @checkbox-change="dialog_checkboxChange" @checkbox-all="dialog_checkboxChange">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column field="productCode" title="商品编号" min-width="100"></vxe-table-column>
          <vxe-table-column field="productName" title="商品名称" min-width="100"></vxe-table-column>
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ rowIndex, row }">
              <el-button type="text" @click="delTable(rowIndex, row)">删除</el-button>
            </template>
          </vxe-table-column>
      </vxe-table>
      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';

export default {
  name: 'product_dialog',
  components: {
    Modal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler(val) {
        this.modalConfig.visible = val;
        this.tableList = [];
        this.selectList = [];
        this.productCode = null;
        this.productName = null;
      },
    },
  },
  data() {
    return {
      // 弹窗配置项
      modalConfig: {
        title: '选择商品',
        visible: false,
        type: 'Modal',
        width: '50%',
      },
      // 是否加载中
      loading: false,
      // 商品编码
      productCode: null,
      productCodeOptions: [],
      // 商品名称
      productName: null,
      productNameOptions: [],
      // 表格数据
      tableList: [],
      selectList: [],
    };
  },
  created() {
    this.remoteMethod();
  },
  methods: {
    /** @desc 远程搜索 * */
    remoteMethod(query, type) {
      const param = {
        pageNum: 1,
        pageSize: 20,
        productCode: type === 'productCode' ? query : null,
        productName: type === 'productName' ? query : null,
      };
      request.post('/mdm/mdmProductController/commonPageList', param).then((res) => {
        if (type === 'productCode') {
          this.productCodeOptions = res.result.data;
        } else if (type === 'productName') {
          this.productNameOptions = res.result.data;
        } else {
          this.productCodeOptions = res.result.data;
          this.productNameOptions = res.result.data;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    /** @desc 清除输入框 * */
    getClear(type) {
      this.remoteMethod(null, type);
    },
    /** @desc 清除输入框 * */
    getChange(val, type) {
      let list = [];
      if (type === 'productCode') {
        list = this.productCodeOptions;
        this.productName = null;
      } else if (type === 'productName') {
        list = this.productNameOptions;
        this.productCode = null;
      }
      list.map((v) => {
        if (v.productCode === val) {
          const { tableList } = this;
          tableList.push(v);
          this.tableList = this.uniqueArr(tableList, 'productCode');
        }
        return v;
      });
    },
    /**
     * @desc 数组去重
     * @param {Array} array 数组
     * @param {String} key 去重字段
     * */
    uniqueArr(array, key) {
      const hash = {};
      const newArr = array.reduce((item, next) => {
        if (!hash[next[key]]) {
          hash[next[key]] = true;
          item.push(next);
        }
        return item;
      }, []);
      return newArr;
    },
    /** @desc table选择 * */
    dialog_checkboxChange({ records }) {
      this.selectList = records;
    },
    /** @desc table删除 * */
    delTable(rowIndex, row) {
      this.tableList.splice(rowIndex, 1);
      const cIndex = this.selectList.findIndex((val) => val.productCode === row.productCode);
      if (cIndex > -1) {
        this.selectList.splice(cIndex, 1);
      }
    },
    /** @desc 点击提交按钮 * */
    dialog_btnConfirm() {
      if (this.selectList.length) {
        this.$emit('dialogSubmit', this.selectList);
      } else {
        this.$message.warning('请至少选择一条数据！');
      }
    },
    /** @desc 点击关闭按钮 * */
    dialog_btnClose() {
      this.$emit('closeDialog');
    },
  },
};
</script>
<style lang="less" scoped>
.vxeTable{
  margin-top: 20px;
}
</style>

<template>
  <div>
    <Modal :modalConfig="modalConfig" @onClose="dialog_btnClose">
      <el-row class="search">
        <el-col :span="8">
          <el-input v-model="params.name" placeholder="请输入组织名称" clearable></el-input>
        </el-col>
        <el-col :span="8" :offset="8">
          <el-button type="primary" @click="handleQuery" icon="el-icon-search">查询</el-button>
          <el-button type="default" @click="handleResetQuery" icon="el-icon-refresh">重置</el-button>
        </el-col>
      </el-row>
      <vxe-table border
                row-id="code"
                ref="xTable"
                class="vxeTable"
                @checkbox-change="dialog_checkboxChange"
                @checkbox-all="dialog_checkboxChange"
                :tree-config="{ children: 'children' }"
                :data="treeData"
                :checkbox-config="{checkRowKeys: checkRowKeys, checkStrictly: true}"
                :expand-config="{expandRowKeys: checkRowKeys}">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column tree-node field="name" title="组织名称"></vxe-table-column>
      </vxe-table>
      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';

export default {
  name: 'org_dialog',
  components: {
    Modal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    orgList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler(val) {
        this.modalConfig.visible = val;
      },
    },
    orgList: {
      deep: true,
      immediate: true,
      handler(val) {
        this.checkRowKeys = [];
        if (val && Array.isArray(val)) {
          val.map((item) => {
            this.checkRowKeys.push(item.code);
            return item;
          });
        }
      },
    },
  },
  data() {
    return {
      // 弹窗配置项
      modalConfig: {
        title: '选择组织',
        visible: false,
        type: 'Modal',
        width: '50%',
      },
      // 列表数据
      treeData: [],
      // 默认选中的
      checkRowKeys: [],
      // 选中的
      selection: [],
      params: { // 添加组织增加搜索条件
        name: '',
      },
    };
  },
  created() {
    this.dialog_getList();
  },
  methods: {
    /** @desc 获取列表 */
    async dialog_getList() {
      try {
        const treeResult = await request.post('/mdm/baseTreeController/orgTree', this.params, { headers: { functionCode: 'select-org' } });
        this.treeData = treeResult ? treeResult.result : [];
      } catch (error) { console.log(error); }
    },
    /** @desc 搜索列表 */
    handleQuery() {
      this.dialog_getList();
    },
    /** @desc 重置查询 */
    handleResetQuery() {
      this.params = {
        name: '',
      };
      this.dialog_getList();
    },
    /** @desc table选择 * */
    dialog_checkboxChange({ records, checked, row }) {
      this.selection = [...records];
      if (checked) {
        records.map((v) => {
          const item = v;
          const index = this.checkRowKeys.findIndex((val) => val === item.code);
          if (index === -1) {
            const transver = (value) => {
              if (value.children) {
                this.$refs.xTable.setCheckboxRow(value, true);
                value.children.map((a) => {
                  const pItem = a;
                  this.selection.push(pItem);
                  this.$refs.xTable.setCheckboxRow(pItem, true);
                  transver(pItem);
                  return a;
                });
              }
            };
            transver(item);
          }
          return item;
        });
      } else {
        this.$refs.xTable.setCheckboxRow(row, false);
      }
    },
    /** @desc 点击提交按钮 * */
    dialog_btnConfirm() {
      if (this.selection.length) {
        this.$emit('dialogSubmit', this.selection);
      } else {
        this.$message.warning('请至少选择一条数据！');
      }
    },
    /** @desc 点击关闭按钮 * */
    dialog_btnClose() {
      this.$emit('closeDialog');
    },
  },
};
</script>
<style lang="less" scoped>
  .search {
      margin-bottom: 10px;
  }
</style>
